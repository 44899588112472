<!-- Page banner Area -->
<!-- <div class="page-banner" style="height: 300px !important;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin-top: 125px;">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Contact.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Contact.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- End Page banner Area -->

<!-- Conatct Info -->
<div style="padding:20px;padding-bottom: 0px;">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.3068294543123!2d74.57325551434634!3d16.860709722219774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1186b803d2705%3A0xafad802573c77f0d!2sGheware%20Financial%20Services!5e0!3m2!1sen!2sin!4v1679042581737!5m2!1sen!2sin"
        width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<div style="padding-bottom: 40px;">
    <div class="container">
        <div class="row" style="border-bottom: 1px solid #eeeeee;">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info" style="border:none;margin-bottom: 0px;">
                    <i class='bx bxs-phone'></i>
                    <h4>Contact Number</h4>
                    <p> <a *ngIf="ContactData!=undefined">+ {{ContactData.CONTACT_NO}}</a></p>
                    <p><a *ngIf="ContactData!=undefined">+ {{ContactData.WHATSAPP_NO}}</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info" style="border:none;margin-bottom: 0px;">
                    <i class='bx bxs-location-plus'></i>
                    <h4>Our Location</h4>
                    <p *ngIf="ContactData!=undefined">{{ContactData.ADDRESS_DETAILS}}</p>
                    <!-- <p>Barbosa Sidney</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info" style="border:none;margin-bottom: 0px;">
                    <i class='bx bxs-envelope'></i>
                    <h4>Email Id</h4>
                    <p><a *ngIf="ContactData!=undefined">{{ContactData.EMAIL_ID}}</a></p>
                    <!-- <p><a href="mailto:info@floza.com">info@floza.com</a></p> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Conatct Info -->

<!-- Contact Area -->
<!-- <div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="contact-form">
            <form id="contactForm" #myform="ngForm">
                <div class="row">
                    <div class="col-md-4 col-md-4">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" required placeholder="* Enter Full Name" 
                            [pattern]=" namepattern" (keypress)="alphaOnly($event)" [(ngModel)]="contact.NAME">
                        </div>
                    </div>

                    <div class="col-md-4 col-md-4">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" id="email" required placeholder="* Enter Email ID"
                             [pattern]="emailpattern" [(ngModel)]="contact.EMAIL_ID">
                        </div>
                    </div>
                    <div class="col-md-4 col-md-4">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" id="phone_number" required placeholder="* Enter Mobile Number"
                            [pattern]="mobpattern"  maxlength="10"
                          (keypress)="omit($event)" [(ngModel)]="contact.MOBILE_NO">
                        </div>
                    </div>

                    <div class="col-md-12 col-md-12">
                        <div class="form-group">
                            <textarea type="text" name="msg_address" id="msg_address" class="form-control" style="min-height: 70px;;max-height: 70px;"
                            required placeholder="* Enter Address" [(ngModel)]="contact.DESCRIPTION"  cols="30" rows="2"></textarea>
                        </div>
                    </div>

                    

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" id="message" class="form-control" cols="30" rows="2" style="min-height: 70px;;max-height: 70px;"
                             required placeholder="* Write your message..." [(ngModel)]="contact.SUBJECT"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-2">
                      
                    </div>
                    <div class="col-lg-4 col-md-4 text-center" *ngIf="!isSpinning">
                        <button type="submit" class="default-btn-one" (click)="save()" style="width: 175px">Send Message</button>
                    </div>

                    <div class="col-lg-4 col-md-4 text-center" *ngIf="isSpinning" style="text-align: center;margin-top: 4px;">
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    
                    
                    
                   

                    <div class="col-lg-4 col-md-4 text-center">
                        <button type="submit" class="default-btn-one" (click)="cancel()" style="width: 175px">Cancel</button>
                    </div>
                    <div class="col-lg-2 col-md-2">
                      
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
<!-- Contact Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->