<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 22px;">
                    <h2>Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                    padding: 20px 20px;">
                    <h2>Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area" style="padding-top: 50px;padding-bottom: 30px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let team of TeamsData">
                <div class="team-card" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                    <div class="team-image">
                        <img *ngIf="TeamsData!=undefined" src="{{serviceUrl}}CommiteeImages/{{team.IMG_URL}}"
                            height="300" width="366" alt="image">
                        <div class="caption" style="text-align: center;align-self: center;">
                            <div class="d-table ">
                                <div class="d-table-cell">
                                    <a class="default-btn-team" (click)="getIdOfTeams(team.ID)"
                                        routerLink="/team/team-details">View More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-text" style="padding: 18px;text-align: left;">
                        <div class="row">
                            <div class="col-12">
                                <h3 *ngIf="TeamsData!=undefined" style="margin-bottom: 10px;font-size: 20px;">
                                    {{team.NAME}}</h3>
                            </div>
                            <div class="col-12">
                                <span *ngIf="TeamsData!=undefined" style="display: flex;margin-bottom: 10px;"> <i
                                        class='bx bxs-user'></i>&nbsp;{{team.POSITION}}</span>
                            </div>
                            <!-- <div class="col-12">
                                <span *ngIf="TeamsData!=undefined" style="display: flex;margin-bottom: 10px;"><i
                                        class='bx bxs-phone'></i>&nbsp;{{team.MOBILE_NO}}</span>
                            </div>
                            <div class="col-12">
                                <span *ngIf="TeamsData!=undefined" style="display: flex;margin-bottom: 10px;"><i
                                        class='bx bxs-envelope'></i>&nbsp;{{team.EMAIL_ID}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="loaded">
            Data Not Found !
        </div> -->
    </div>
</div>