import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  BlogData:any=[]
  serviceUrl = appkeys.retriveimgUrl
  serviceUrl1=appkeys.retriveimgUrl + 'newsUpdateImages/'
  BlogData1:any=[]
  searchText: string = "";
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }

  constructor(public apiService: ApiService ) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }

  ngOnInit(): void {
    this.apiService.getNewsAndUpdates(0, 0, '', 'desc', ' AND ID=' +localStorage.getItem("BlogssId"))
    .subscribe(data => {
      this.BlogData = data['data'][0]
    })

    this.apiService.getNewsAndUpdates(1, 5, '', 'desc',' AND IS_ACTIVE=1 AND ID!='+localStorage.getItem("BlogssId"))
    .subscribe(data => {
      this.BlogData1 = data['data']
    })
  }


  searchBlogs(event:any)
  {
  
  var filter='';
  if(event==''){
    filter='';
  }else{
    filter=" AND TITLE like '%"+event+"%'";
  }


  this.apiService.getNewsAndUpdates(1, 5, '', 'desc',' AND IS_ACTIVE=1' +filter)
  .subscribe(data => {
    this.BlogData1 = data['data']
  })
  }


  getIdOfService(event){
    this.apiService.getNewsAndUpdates(0, 0, '', 'desc', ' AND ID=' +event)
    .subscribe(data => {
      this.BlogData = data['data'][0]
    })
  }

}
