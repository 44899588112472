<div class="carouselHeight carouselh" style="background-color: #ffc458;">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let data of webbanner;let i =index;">
      <ng-template carouselSlide>
        <div class="carouselHeight"
          [ngStyle]="{'background': 'url('+ img_url+data.IMG_URL +') ', 'background-repeat': 'no-repeat', 'background-size': '100% 100%','background-color':'#dd6584'}">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center mt-50">
                  <div class="col-lg-6 col-md-6">
                    <div class="slider-three-text">
                      <span *ngIf="webbanner!=undefined" class="tct"
                        [style.color]="data.COLOR_CODE_T1 +' !important'" [innerHTML]="data.SUB_TITLE"></span>
                      <h1 *ngIf="webbanner!=undefined" class="tcmt" [style.color]="data.COLOR_CODE_NAME +' !important'" [innerHTML]="data.NAME"></h1>
                      <p *ngIf="webbanner!=undefined" class="tct" [style.color]="data.COLOR_CODE_T2 +' !important'" [innerHTML]="data.SUB_TITLE2"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- Services Slider Area -->
<div style="padding-top: 70px;
padding-bottom: 0px;">
  <div class="container" *ngIf="ServicesData.length>0">
    <div class="section-title" style="max-width: 100%;margin-bottom: 30px;">
      <span>Our Services</span>
      <h3 class="fsizess">Discover a world of personalized healthcare excellence at Shree Hospital Services.</h3>
    </div>
    <div [options]="ServiceOption" *ngIf="Is_ServiceShowcards">
      <div class="row articles">
        <div class="col-12 col-sm-6 col-lg-4" *ngFor="let ser of ServicesData">
          <article>
            <div class="article-wrapper">
              <figure>
                <img *ngIf="ServicesData!=undefined" src="{{serviceUrl}}ServiceImages/{{ser.IMAGE_URL}}" style="max-height: 203px;" width="100%" alt="image">
              </figure>
              <div class="article-body">
                <h3 *ngIf="ServicesData!=undefined" style="height: 43px;" class="limit txtssss">{{ser.TITLE}}</h3>
                <a routerLink="/services-facilities" (click)="getIdOfService(ser.ID)" class="read-more readtxts">
                  View More
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>

    <div *ngIf="Is_ServiceShowcards1">
      <owl-carousel-o [options]="ServiceOption">
        <ng-container *ngFor="let ser of ServicesData">
          <ng-template carouselSlide>
            <article>
              <div class="article-wrapper">
                <figure>
                  <img *ngIf="ServicesData!=undefined" src="{{serviceUrl}}ServiceImages/{{ser.IMAGE_URL}}" alt="image"  height="203" width="100%">
                </figure>
                <div class="article-body">
                  <h3 *ngIf="ServicesData!=undefined" style="height: 43px;" class="limit txtssss">{{ser.TITLE}}</h3>
                  <a routerLink="/services-facilities" (click)="getIdOfService(ser.ID)" class="read-more readtxts">
                    View More
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            </article>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
<!-- End Services Slider Area -->

<!-- Safe Area -->
<div class="safe-area" style="padding-top: 50px;
padding-bottom: 50px;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-img-three">
          <img *ngIf="AboutData!=undefined" src="{{SERVER_URL}}AboutImages/{{AboutData.IMG_URL}}" height="550"
            width="100%" alt="Image">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-contant-others">
          <div class="section-title">
            <span>About Us</span>
            <h2 *ngIf="AboutData!=undefined" [innerHTML]="AboutData.TITLE"></h2>
          </div>
          <div class="about-two-text">
            <div *ngIf="AboutData!=undefined" [innerHTML]="AboutData.TEXT" style="text-align: justify;"></div>
            <a *ngIf="!openab && AboutData.SUB_DESCRIPTION!=null && AboutData.SUB_DESCRIPTION!=''" (click)="openaboutsec()" class="default-btn-one btn-bs" style="margin-top: 10px;cursor: pointer;padding: 10px 19px;"  >View More</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center" style="margin-top: 15px;" *ngIf="openab && AboutData!=undefined">
      <div class="col-12">
        <div class="about-contant-others">
          <div class="about-two-text">
            <div *ngIf="AboutData!=undefined" [innerHTML]="AboutData.SUB_DESCRIPTION" style="text-align: justify;"></div>
            <a *ngIf="openab && AboutData.SUB_DESCRIPTION!=null && AboutData.SUB_DESCRIPTION!=''" (click)="openaboutsec1()" class="default-btn-one btn-bs" style="margin-top: 10px;cursor: pointer;padding: 10px 19px;"  >View Less</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="padding-top: 50px;
padding-bottom: 40px; background: #f8f9fb;">
  <div class="container" *ngIf="BlogData.length>0">
    <div class="section-title" style="max-width: 100%;margin-bottom: 30px;">
      <span>Our Facilities</span>
      <h3 class="fsizess">Experience healthcare excellence through Shree Hospital's advanced facilities.</h3>
    </div>
    <div [options]="BlogOption" *ngIf="Is_blogShow">
      <div class="row articles">
        <div class="col-12 col-sm-6 col-lg-4" *ngFor="let ser of BlogData">
          <article>
            <div class="article-wrapper">
              <figure>
                <img *ngIf="BlogData!=undefined" src="{{blogUrl}}FaciliteImages/{{ser.IMAGE_URL}}" alt="image" height="203" width="100%">
              </figure>
              <div class="article-body">
                <h3 *ngIf="BlogData!=undefined" style="height: 43px;" class="limit txtssss">{{ser.TITLE}}</h3>
                <a routerLink="/services_facilities" class="read-more readtxts">
                  View More
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>

    <div *ngIf="Is_blogShow1">
      <owl-carousel-o [options]="BlogOption">
        <ng-container *ngFor="let ser of BlogData">
          <ng-template carouselSlide>
            <article>
              <div class="article-wrapper">
                <figure>
                  <img *ngIf="BlogData!=undefined" src="{{blogUrl}}FaciliteImages/{{ser.IMAGE_URL}}" height="203" width="100%" alt="image">
                </figure>
                <div class="article-body">
                  <h3 *ngIf="BlogData!=undefined" style="height: 43px;" class="limit txtssss">{{ser.TITLE}}</h3>
                  <a routerLink="/services_facilities" class="read-more readtxts">
                    View More
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            </article>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>

<div style="padding-top: 0px;
padding-bottom: 40px; background: #f8f9fb;">
  <div class="container">
    <div class="section-title" style="max-width: 100%;margin-bottom: 30px;">
      <span>Shree Hospital: Voices of Quality Care</span>
      <h3 class="fsizess">Reviews for Shree Hospital highlight healthcare excellence, praised by patients and professionals.</h3>
    </div>
<div>
  <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/207625' frameborder='0' width='100%' height='800'></iframe>
</div>
</div>
</div>
<!-- <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
<div class="elfsight-app-97538803-146e-426d-9ebe-6420be2a4376"></div> -->