<!-- Header Area -->
<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li><a href="mailto:shree.hospital@rediffmail.com" *ngIf="ContactData!=undefined"><i
                                    class='bx bxs-envelope'></i>{{ContactData.EMAIL_ID}}</a></li>
                        <li><a href="tel:9860798919" *ngIf="ContactData!=undefined"><i
                                    class='bx bxs-phone-call'></i>{{ContactData.CONTACT_NO}}</a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <!-- <li class="mr-20"><a routerLink="/">Facilities</a></li> -->
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.FB_LINK}}" target="_blank"><i
                                    class='bx bxl-facebook' style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.TW_LINK}}" target="_blank"><i
                                    class='bx bxl-twitter' style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.SOCIAL_LINK}}" target="_blank"><i
                                    class='bx bxl-linkedin' style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.INSTA_LINK}}" target="_blank"><i
                                    class='bx bxl-instagram' style="background:#0672e1; color: white;"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <!-- <img src="assets/img/GhewareLogo.jpg" alt="logo"> -->
                            Shree Hospital
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand fontsizenav" routerLink="/" style="font-weight: 700;color: #f38737;">
                        <img src="assets/img/Logo_Final_2.png" width="80" height="80" alt="logo">
                        <!-- Shree Hospital -->
                        
                    </a>
                    <div *ngIf="buttonToggler">
                        <button (click)="buttonToggler = !buttonToggler" style="border: none;" class="navbar-toggler"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span style="color: #f7941d;" class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div *ngIf="!buttonToggler">
                        <button (click)="buttonToggler = !buttonToggler"
                            style="border: none; background-color: transparent;" class="navbar-toggler" type="button"
                            data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path
                                    d="M.646 15.354a.5.5 0 0 1-.708-.708L15.354.646a.5.5 0 0 1 .708.708L.646 15.354z" />
                                <path
                                    d="M15.354 15.354a.5.5 0 0 1-.708.708L.646.646a.5.5 0 0 1 .708-.708L15.354 15.354z" />
                            </svg>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse " id="navbarNav">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item "><a style="margin-left: 17px;" routerLink="/"
                                class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                            <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Committee</a></li>
                            <li class="nav-item"><a routerLink="/services-facilities" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Services & Facilities</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>