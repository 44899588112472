import { Component, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { enquiry } from 'src/modal/enquiry';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent {
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }


  constructor(private api: ApiService, private tost: ToastrService) { 
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
  }

  ngOnInit(): void {
    this.api.getCountryMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.details = data["data"]
      }
    });
  }
  isSpinning = false;
  namepattern = /^([a-zA-Z'-.]+ [a-zA-Z'-.]+ [a-zA-Z'-.]+)$/
  mobpattern = /^[7-9]\d{9}$/
   telpattern=/^- +()0-9]/ 
  emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  details: any = [];
  COUNTRY_ID = '';


  enquiry: enquiry = new enquiry();
  isOk = true;
  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  myform: NgForm;


  setid(event: any) {
    var countryidss:any
    countryidss = this.details.filter(item => item.NAME == event);
  }

  countryMap(event){
var countryidss:any
countryidss = this.details.filter(item => item.NAME == event);
this.enquiry.COUNTRY_ID = countryidss[0].ID;
  }

  save() {


    if ((this.enquiry.FULL_NAME == '' || this.enquiry.FULL_NAME == undefined)
      && (this.enquiry.COMPANY_NAME == '' || this.enquiry.COMPANY_NAME == undefined)
      && (this.enquiry.MOBILE_NO == undefined)

      && (this.enquiry.EMAIL_ID == '' || this.enquiry.EMAIL_ID == undefined)
      // && (this.enquiry.TELEPHONE_NO == undefined)
      // && (this.enquiry.FAX_NO == '' || this.enquiry.FAX_NO == undefined)
      && (this.enquiry.ADDRESS == '' || this.enquiry.ADDRESS == undefined)
      && (this.enquiry.COUNTRY_ID== undefined)
      && (this.enquiry.CITY == '' || this.enquiry.CITY == undefined)
      && (this.enquiry.POSTAL_CODE == '' || this.enquiry.POSTAL_CODE == undefined)
      && (this.enquiry.ENQUIRY_DETAILS == '' || this.enquiry.ENQUIRY_DETAILS == undefined)) {
      this.tost.error('Please Fill All Required Fields');

    } else if (this.enquiry.FULL_NAME == '' || this.enquiry.FULL_NAME == undefined) {
      this.tost.error('Please Enter Full Name');
    }
    else if (!this.namepattern.test(this.enquiry.FULL_NAME)) {
      this.tost.error('Please Enter Valid Name')
    }

    else if (this.enquiry.COMPANY_NAME == '' || this.enquiry.COMPANY_NAME == undefined) {
      this.tost.error('Please Enter Company Name');
    }

    else if (this.enquiry.MOBILE_NO == undefined) {
      this.tost.error('Please Enter Mobile Number')
    }
    else if (!this.mobpattern.test(this.enquiry.MOBILE_NO)) {
      this.tost.error('Please Enter Valid Mobile Number ')
    }

    else if (this.enquiry.EMAIL_ID == '' || this.enquiry.EMAIL_ID == undefined) {
      this.tost.error('Please Enter Email ID')
    }
    else if (!this.emailpattern.test(this.enquiry.EMAIL_ID)) {
      this.tost.error('Please Enter Valid Email ID')
    }
    // else if (this.enquiry.TELEPHONE_NO == undefined) {
    //   this.tost.error('Please Enter Telephone Number')
    // }
    // else if (this.enquiry.FAX_NO == '' || this.enquiry.FAX_NO == undefined) {
    //   this.tost.error('Please Enter Fax Number')
    // }

    else if (this.enquiry.ADDRESS == '' || this.enquiry.ADDRESS == undefined) {
      this.tost.error('Please Enter Address')
    }

    else if (this.enquiry.COUNTRY_ID == undefined) {
      this.tost.error('Please Select Country')
    }
    else if (this.enquiry.CITY == '' || this.enquiry.CITY == undefined) {
      this.tost.error('Please Enter City')
    }
    else if (this.enquiry.POSTAL_CODE == '' || this.enquiry.POSTAL_CODE == undefined) {
      this.tost.error('Please Enter Postal Code')
    }else if (this.enquiry.POSTAL_CODE .length<6) {
      this.tost.error('Please Valid Postal Code')
    }
    else if (this.enquiry.ENQUIRY_DETAILS == '' || this.enquiry.ENQUIRY_DETAILS == undefined) {
      this.tost.error('Please Enter Enquiry Details')
    }

    else {
      this.isSpinning = true;
      this.api.createEnquiryMaster(this.enquiry).subscribe((successCode) => {
        if (successCode['code'] == '200') {
          this.tost.success('Enquiry Details Saved Successfully..');

          this.cancel();
          this.isSpinning = false;
        } else {

          this.tost.error('Failed To Save Enquiry Details..!');
          this.cancel();
          this.isSpinning = false;
        }
      });

    }


  }



  cancel() {
    //  this.myform.reset();
    this.enquiry = new enquiry();
this.COUNTRY_ID=null;

  }
}
