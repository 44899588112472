import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  year: any;
  constructor(public apiService:ApiService,private sanitizer: DomSanitizer) { }

  ContactData:any=[];
  LATITUDE: any;
  LONGITUDE: any;
  url: SafeUrl = ''
  AboutData:any=[];
  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
    this.getContactInfo();

    this.apiService.getAbout(0, 0, '', 'desc', ' AND STATUS=1 AND IS_ON_FOOTER=1')
      .subscribe(data => {
        this.AboutData = data['data'][0];
      })
  }

  getContactInfo() {
    this.apiService.getContactInfo(0,0, '', 'desc', '')
      .subscribe(data => {
        this.ContactData = data['data'][0];
        this.LATITUDE = data['data'][0]['LATITUDE'];
          this.LONGITUDE = data['data'][0]['LONGITUDE'];
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=" + this.LONGITUDE + "," + this.LATITUDE + "&output=embed");
      })
  }
}
