import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  TeamsData: any = []
  serviceUrl = appkeys.retriveimgUrl
  loaded: boolean = false;
  scrHeight: any;
  scrWidth: any;
  show50: boolean = false;

  @HostListener('window:resize', ['$event']) getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(public apiService: ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) {
      this.show50 = true;
    } else {
      this.show50 = false;
    }
  }

  ngOnInit(): void {
    this.getTeamsData()
  }

  getTeamsData() {
    this.loaded = true;
    this.apiService.getCommeti(0, 0, 'SEQUENCE_NO', 'asc', ' AND STATUS=1')
      .subscribe(data => {
        if (data['code'] == "200") {
          this.TeamsData = data['data'];
          this.loaded = false;
        }
      }, err => {
        this.loaded = false;
      })
  }

  getIdOfTeams(event) {
    localStorage.setItem("TeamsId", event);
  }
}