import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  show50: boolean = false;
  @HostListener('window:resize', ['$event']) getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor() {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) {
      this.show50 = true;
    } else {
      this.show50 = false;
    }
   }

  ngOnInit(): void {
  }

}
