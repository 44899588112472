import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  ContactData:any=[];
  buttonToggler = true;
  scrHeight: any;
  scrWidth: any;
  GalleryIcon:boolean = true;
  AboutUsbtn :boolean = true;
 

  constructor(public apiService:ApiService) { }

  ngOnInit(): void {
    this.getContactInfo()
  }

  getContactInfo() {
    this.apiService.getContactInfo(1, 1, '', 'desc', '')
      .subscribe(data => {
        this.ContactData = data['data'][0]
      })
  }

}
