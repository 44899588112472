<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Event Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Event Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Event Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Event Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End Page banner Area -->

<!--Album area-->


<!-- Gallery Area -->
<div class="gallery-area  pb-70" style="padding-top: 40px;padding-bottom: 30px;">
    <div class="container">
        <div class="gallery-title" style="margin-bottom: 15px;">
            <h3>Our Events Gallery</h3>
        </div>

        <div class="col-md-12" class="gallery">
            <ul class="gallery-filter list-unstyled list-inline text-center" style="margin-bottom: 40px;">
           
                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor1 == 1 ? {'background':'#f7941d'} : {'background':'#8732de'}"
                (click)="getall()">
                 <span>All</span></li>

                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor == j ? {'background':'#f7941d'} : {'background':'#8732de'}"
                 *ngFor="let x of albums;let i=index; let j=index;" (click)="get(x.ID)" (click)=" getI(i)">
                 <span *ngIf="albums!=undefined">{{x.TITLE}}</span></li>
                
             
            </ul>
        </div> 

        <div class="row" >
            <!-- <div class="col-lg-4 col-md-6" *ngFor="let x of photos">
                <div class="floza-gallery" >
                    <img *ngIf="photos!=undefined" src="{{serviceUrl}}photos/{{x.IMG_URL}}" alt="image" 
                    height="191" width="366">
                    <div class="caption ">
                        <div class="d-table ">
                            <div class="d-table-cell">
                                <a *ngIf="photos!=undefined" href="{{serviceUrl}}photos/{{x.IMG_URL}}"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


            <div class="col-lg-4 col-md-6" *ngFor="let x of photos">
                <div class="floza-gallery" style="margin-bottom: 20px;" >
                    <img *ngIf="photos!=undefined" src="{{serviceUrl}}photos/{{x.IMG_URL}}" alt="image" 
                    height="191" width="366" >
                    
                    <a (click)="imgClick(x.IMG_URL)">
                    <div class="caption ">
                        <div class="d-table ">
                            <div class="d-table-cell">
                                <a (click)="imgClick(x.IMG_URL)"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                   </a>
                </div>
                <h5 style="text-align: center;margin-bottom: 20px;color:#8732DE;">{{x.NAME}}</h5>
            </div>
            <div *ngIf="Photocount==0" style="margin-top: 55px;">
                  <h3 class="text-center" style="font-size: larger;color: red;">Data Not Found..!</h3>
            </div>
        </div>
    </div>
</div>
<!-- End Gallery Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->





<button type="button" class="btn btn-primary" style="display:none" data-bs-toggle="modal" #ngOtpInput data-bs-target="#exampleModal">
    Launch demo modal
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <ul *ngFor="let x of photos; let i= index;first as isFirst" style="list-style: none;padding-left: 0px;">
                        <div *ngIf="isFirst" [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
                            <li ><img   src="{{serviceUrl}}photos/{{imgHover}}"
                                    class="d-block w-100" alt="..." height="100%" width="100%"></li>
                        </div>
                        <div *ngIf="!isFirst" [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
                            <li><img src="{{serviceUrl}}photos/{{x.IMG_URL}}"
                                    class="d-block w-100" alt="..." height="100%" width="100%"></li>
                        </div>
                    </ul>
                </div>
                <button class="carousel-control-prev" type="button"
                    data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button"
                    data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
    </div>
    </div>
  </div>