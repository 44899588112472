import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  BlogData:any=[];
  serviceUrl = appkeys.retriveimgUrl
  totalcount:any=0
  pageSize:any=9
  pages:any=[]


    
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }
  constructor(public apiService: ApiService ) { 
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
  }

  ngOnInit(): void {
    this.getBlogData();
  }

  getBlogData() {
    this.apiService.getNewsAndUpdates(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        this.BlogData = data['data']
       this.totalcount = data['count'];
       this.totalPages = Math.ceil(this.totalcount / this.pageSize);
for (var i = 0; i <= (this.totalcount / this.pageSize); i++) {

 this.pages.push(i + 1);

}
        
      })
  }

  pagination(p: any) {
 this.apiService.getNewsAndUpdates(p, this.pageSize, '', 'desc', 'AND IS_ACTIVE=1').subscribe(data => {
    
if (data.code == "200") {
  this.BlogData = [...[], ...data['data']];
  this.currentPage = p;
  } 
else { 
 this.BlogData = []
 this.totalPages = 1;
    
 }
}) 
}

currentPage = 1;
   totalPages = 1;
 
   previousPage() {
     if (this.currentPage > 1) {
       this.pagination(this.currentPage - 1);
     }
   }
   
   nextPage() {
     if (this.currentPage < this.totalPages) {
       this.pagination(this.currentPage + 1);
     }
   }


  getIdOfService(event){
    localStorage.setItem("BlogssId",event);
      }

}
