import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 img_url11=appkeys.retriveimgUrl + 'photos/'
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }

  constructor(private api:ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }
img_url=appkeys.retriveimgUrl + ''
serviceUrl=appkeys.retriveimgUrl
isSpinning = false;
Photocount:any=[]



  ngOnInit(): void {
// this.isSpinning=true;

    this.api.getallAlbums(0,0,'SEQUENCE_NO','asc','AND STATUS=1 ')
    .subscribe(data => {
      if (data['code'] == 200 )
        this.albums = data['data']
        var ID=data['data'][0]['ID']
        if(this.albums.length>0)
        {
          this.api.getGalleryMaster(0,0,'SEQUENCE_NO','asc','AND STATUS=1')
          .subscribe(data => {
            this.Photocount=data['count']
            if (data['code'] == 200 )
              this.photos = data['data']
            
              // this.isSpinning=false;
          })
        }
    })

  }

  get(event:any)
{
  this.api.getGalleryMaster(0,0,'SEQUENCE_NO','asc','AND STATUS=1 AND ALBUM_ID= '+event)
  .subscribe(data => {
    this.Photocount=data['count']
    if (data['code'] == 200)
      this.photos = data['data']
      // this.isSpinning=false;
  })
  this.photos=[];
}
photos:any;
  albums:any;


  showcolor:any;
  getI(event:any){
    this.showcolor=event;
    this.showcolor1=0
  }
	@ViewChild('ngOtpInput') ngOtpInput: any;
imgHover:any;
  imgClick(event:any){
    this.imgHover=event;
    this.ngOtpInput.nativeElement.click();
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: { 0: { items: 1 } },
    nav: false
  }

  showcolor1=1
  getall()
{
  this.api.getGalleryMaster(0,0,'SEQUENCE_NO','asc','AND STATUS=1')
  .subscribe(data => {
    if (data['code'] == 200 && data['data'].length > 0)
      this.photos = data['data']
     
      this.showcolor=null
      this.showcolor1=1
  })
}

}
