import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {
  ServicesData:any=[]
  serviceUrl = appkeys.retriveimgUrl

  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
   }
  constructor(public apiService: ApiService ) { 
 
      this.apiService.getServices(0, 0, '', 'desc', ' AND ID=' +localStorage.getItem("ServiceId"))
        .subscribe(data => {
          this.ServicesData = data['data'][0]
        })

        this.getScreenSize(event);
        if (this.scrWidth <= 500) 
        { 
         this.show50 = true;
       }else{
         this.show50 = false;
       }
  }
  
  serviceId = localStorage.getItem("ServiceId");

  getI(event:any){
    this.serviceId=event;
    
  }

  ngOnInit(): void {


    this.getServisesData();
  }
  ServicesData1:any=[];

  getServisesData() {
    this.apiService.getServices(1,8, '', 'desc', 'AND IS_ACTIVE=1')
      .subscribe(data => {
        this.ServicesData1 = data['data']
      })
  }

  getIdOfService(event){
localStorage.setItem("ServiceId",event);
this.apiService.getServices(0, 0, '', 'desc', ' AND ID=' +localStorage.getItem("ServiceId"))
.subscribe(data => {
  this.ServicesData = data['data'][0];
})
  }
}
