import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  CompanyProfile: any = [];
  AbouUrl = appkeys.retriveimgUrl

  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
   }
  constructor(public apiService: ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }

  ngOnInit(): void {
    this.getAboutHome();
  }


  
  getAboutHome() {
    this.apiService.getCompanyProfile(0, 0, '', 'desc', ' AND STATUS=1 AND IS_ON_FOOTER=0')
      .subscribe(data => {
        this.CompanyProfile = data['data'][0];
      })
  }
}
