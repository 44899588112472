<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/teami.jpg);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Service Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/services-facilities">Services</a></li>
                        <li>Service Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/teami.jpg);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Service Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/services-facilities">Services</a></li>
                        <li>Service Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="single-services-area" style="padding-top: 50px;
padding-bottom: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text"> 
                    <div class="service-image">
                        <img *ngIf="ServicesData!=undefined" src="{{serviceUrl}}serviceImages/{{ServicesData.IMG_URL}}" 
                        height="420" width="756" alt="image">
                    </div>
                    <h3 *ngIf="ServicesData!=undefined">{{ServicesData.TITLE}}</h3>
                    <p style="text-align: justify;" *ngIf="ServicesData!=undefined">{{ServicesData.DESCRIPTION}}</p>
                    <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <p>NNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo</p> -->
                    <!-- <div class="image">
                        <img src="assets/img/services/single-services2.jpg" alt="image">
                    </div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces</p> -->
                </div> 
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Our Services</h3>

                    <ul>
                        <!-- <li><a routerLink="/services-details" style="padding-left: 21px;"><svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f7941d" class="bi bi-file-earmark-bar-graph-fill" viewBox="0 0 16 16">
                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z"/>
                          </svg>&nbsp;&nbsp;Preparation of project report</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-rupee'></i>Loan proposals from banks financial institutions</a></li>
                        <li><a routerLink="/services-details" style="padding-left: 21px;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #f7941d;">
                            <path d="M2 8v4.001h1V18H2v3h16l3 .001V21h1v-3h-1v-5.999h1V8L12 2 2 8zm4 10v-5.999h2V18H6zm5 0v-5.999h2V18h-2zm7 0h-2v-5.999h2V18zM14 8a2 2 0 1 1-4.001-.001A2 2 0 0 1 14 8z"></path></svg>
                            &nbsp;&nbsp;Services of state & central subsidies</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-transfer'></i>Services to Bakers</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-home' ></i>Services in Real Estate project funding</a></li> -->
                        <li *ngFor="let d of ServicesData1">
                            <a [ngClass]="d.ID == serviceId?'listoption':'listoption1'" routerLink="/services-details" *ngIf="ServicesData1!=undefined"  (click)="getIdOfService(d.ID)" (click)="getI(d.ID)">
                            <i class='bx bx-check'>

                            </i >{{d.TITLE}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->