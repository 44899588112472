<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 22px;">
                    <h2>Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/team">Team</a></li>
                        <li><a>Team Detail</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                    padding: 20px 20px;">
                    <h2>Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/team">Team</a></li>
                        <li><a>Team Detail</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="our-services-area" style="padding-top: 50px;padding-bottom: 30px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="profile-card-4 z-depth-3">
                    <div class="card">
                        <div class="card-body text-center rounded-top"
                            style="background-color:rgba(247,148,29,.831372549);">
                            <div class="user-box">
                                <img *ngIf="TeamsData1!=undefined"
                                    src="{{serviceUrl}}CommiteeImages/{{TeamsData1.IMG_URL}}" height="200px"
                                    width="200px" alt="image" style="border-radius: 10px;">
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="list-group shadow-none">
                                <li class="list-group-item">
                                    <div class="list-icon">
                                        <i class='bx bxs-user'></i>
                                    </div>
                                    <div class="list-details">
                                        <span *ngIf="TeamsData1!=undefined">{{TeamsData1.NAME}}</span>
                                    </div>
                                </li>
                                <!-- <li class="list-group-item">
                                    <div class="list-icon">
                                        <i class='bx bxs-phone'></i>
                                    </div>
                                    <div class="list-details">
                                        <span *ngIf="TeamsData1!=undefined">{{TeamsData1.MOBILE_NO}}</span>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="list-icon">
                                        <i class='bx bxs-envelope'></i>
                                    </div>
                                    <div class="list-details">
                                        <span *ngIf="TeamsData1!=undefined">{{TeamsData1.EMAIL_ID}}</span>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card z-depth-3">
                    <div class="card-body">
                        <ul class="nav nav-pills nav-pills-primary nav-justified">
                            <li class="nav-item" (click)="click1()" style="cursor: pointer;">
                                <a class="nav-link  show"
                                    [ngClass]="showClick1 && !showClick2 && !showClick3 ? 'active' : ''"><span
                                        class="hidden-xs">Basic Information</span></a>
                            </li>
                            <li class="nav-item" (click)="click2()" style="cursor: pointer;">
                                <a class="nav-link"
                                    [ngClass]="!showClick1 && showClick2 && !showClick3 ? 'active' : ''"><span
                                        class="hidden-xs">Teaching Experience
                                    </span></a>
                            </li>
                            <li class="nav-item" (click)="click3()" style="cursor: pointer;">
                                <a class="nav-link"
                                    [ngClass]="!showClick1 && !showClick2 && showClick3 ? 'active' : ''">
                                    <span class="hidden-xs">Social commitments </span></a>
                            </li>
                        </ul>
                        <div class="tab-content" style="padding: 0px;margin-top: 30px;"
                            *ngIf="showClick1 && !showClick2 && !showClick3">
                            <div class="tab-pane active show">
                                <div class="about-info-area" style="padding-bottom: 20px;">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 fscroll"
                                                *ngIf="TeamsData3!=''">
                                                <div class="about-info-card">
                                                    <ul>
                                                        <div class="card" *ngFor="let data of TeamsData3">
                                                            <li style="margin: 10px;"><i style="font-weight: bolder;"
                                                                    class='bx bx-check'></i>
                                                                {{data}}</li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
                                                style="height: 225px;text-align: center;" *ngIf="TeamsData3==''">
                                                <p style="margin-top: 80px;font-size: 25px;">No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" style="padding: 0px;margin-top: 30px;"
                            *ngIf="!showClick1 && showClick2 && !showClick3">
                            <div class="tab-pane active show">
                                <div class="about-info-area" style="padding-bottom: 20px;">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 fscroll"
                                                *ngIf="TeamsData2!=''">
                                                <div class="about-info-card">
                                                    <ul>
                                                        <div class="card" *ngFor="let data of TeamsData2">
                                                            <li style="margin: 10px;"><i style="font-weight: bolder;"
                                                                    class='bx bx-check'></i>
                                                                {{data}}</li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
                                                style="height: 225px;text-align: center;" *ngIf="TeamsData2==''">
                                                <p style="margin-top: 80px;font-size: 25px;">No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" style="padding: 0px;margin-top: 30px;"
                            *ngIf="!showClick1 && !showClick2 && showClick3">
                            <div class="tab-pane active show">
                                <div class="about-info-area" style="padding-bottom: 20px;">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 fscroll"
                                                *ngIf="TeamsData4!=''">
                                                <div class="about-info-card">
                                                    <ul>
                                                        <div class="card" *ngFor="let data of TeamsData4">
                                                            <li style="margin: 10px;"><i style="font-weight: bolder;"
                                                                    class='bx bx-check'></i>
                                                                {{data}}</li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
                                                style="height: 225px;text-align: center;" *ngIf="TeamsData4==''">
                                                <p style="margin-top: 80px;font-size: 25px;">No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>