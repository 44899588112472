export const appkeys = {
    // // // //Live
    gmUrl: 'https://gm.tecpool.in:8079/',
    baseUrl: 'https://admin.shreehospitalkarad.com:9789/',
    url: 'https://admin.shreehospitalkarad.com:9789/',
    imgUrl: 'https://admin.shreehospitalkarad.com:9789/api/upload/',
    retriveimgUrl: 'https://admin.shreehospitalkarad.com:9789/static/',

    // // // //TESTING
    // gmUrl: 'https://gm.tecpool.in:8079/',
    // baseUrl: 'http://shreeadmin.uvtechsoft.com:9788/',
    // url: 'http://shreeadmin.uvtechsoft.com:9788/',
    // imgUrl: 'http://shreeadmin.uvtechsoft.com:9788/api/upload/',
    // retriveimgUrl: 'http://shreeadmin.uvtechsoft.com:9788/static/',
    
    // //LOCAL
    // gmUrl: 'https://gm.tecpool.in:8078/',
    // baseUrl: 'http://192.168.29.170:5023/',
    // url: 'http://192.168.29.170:5023/',
    // imgUrl: 'http://192.168.29.170:5023/api/upload/',
    // retriveimgUrl: 'http://192.168.29.170:5023/static/',



    // Local 
    // Api Key : HIvl28a6pGYa0PWxRK4DEHqeTlrB6mRs
    // application key : nvitva1bA9jeUgip



    // Testing
//     APIKEY = 'lhxC0QEzCxrEZDyq3OXiHEWBAIzCIrXa'
// APPLICATION_KEY = 'ZSmX9jxdV2olKGtr'


  };

// admin.shreehospitalkarad.com:9789
// application key - gmmAq69w7LsCdKcN
// api key - p6TXJq5vUoLLzQtceIshhHwvahkDvzdg
// shreehospitalkarad.com - website