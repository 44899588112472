
<!-- End Page banner Area -->
<div class="freight-area freight-area-two" style="padding-top: 50px;
padding-bottom: 20px;">

    <div class="container" *ngIf="BlogData.length>0">
        <div class="section-title " style="max-width: 800px;margin-bottom: 25px;">
            <span>Our News</span>
            <h3>Read Our Latest News and Updates</h3>
        </div>
        <div [options]="BlogOption" *ngIf="Is_blogShow">
        
            <div class="row">
                <div class="col-12" style="margin-bottom: 32px;" *ngFor="let data of BlogData">
                    <div class="outer-div">
                        <div class="inner-div">
                          <div class="front">
                            <div class="front__bkg-photo">
                                
                            </div>
                            <div class="front__face-photo">
                                <img src="{{blogUrl}}FaciliteImages/{{data.IMAGE_URL}}" alt="team">
                            </div>
                            <div class="front__text">
                              <h3 class="front__text-header">{{data.TITLE}}</h3>
                              <p class="front__text-para"> <i class='bx bxs-phone'></i>{{data.TEXT}}</p>
                            </div>
                          </div>
                          <div class="back">
                            <div class="social-media-wrapper">
                            <a style="margin-top: 20px;" routerLink="/" class="bannerBtn mr-3 mt-3">View More<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10 10h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4z"/></svg></a>
                        </div>
                          </div>
                      
                        </div>
                      </div>
                </div>
                </div>
        </div>


        <div *ngIf="Is_blogShow1" >
            <owl-carousel-o  [options]="BlogOption">
              <ng-container  *ngFor="let data of BlogData">
                
                        <ng-template carouselSlide > 
                            <div class="row">
                                <div class="col-12" style="margin-bottom: 32px;" >
                                    <div class="outer-div">
                                        <div class="inner-div">
                                          <div class="front">
                                            <div class="front__bkg-photo">
                                                
                                            </div>
                                            <div class="front__face-photo">
                                                <img src="assets/img/Header/Home1.png" alt="team">
                                            </div>
                                            <div class="front__text">
                                              <h3 class="front__text-header">{{data.TITLE}}</h3>
                                              <p class="front__text-para"> <i class='bx bxs-phone'></i>{{data.TEXT}}</p>
                                            </div>
                                          </div>
                                          <div class="back">
                                            <div class="social-media-wrapper">
                                            <a style="margin-top: 20px;" routerLink="/" class="bannerBtn mr-3 mt-3">View More<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10 10h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4z"/></svg></a>
                    </div>
                                          </div>
                                      
                                        </div>
                                      </div>
                                </div>
                                </div>
                        </ng-template>
                 
               
              </ng-container>
            </owl-carousel-o>
        </div> 
    </div>
</div>
<!-- Team Area -->
<!-- <div class="team-area" style="padding-top: 50px;padding-bottom: 30px;">
    <div class="container">
        <div class="section-title" style="margin-bottom: 35px;">
            <span>Our Team</span>
            <h3>Intelligence Heros Make The Company Proud</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" style="margin-bottom: 32px;" *ngFor="let team of TeamsData1">
                <div class="outer-div">
                    <div class="inner-div">
                      <div class="front">
                        <div class="front__bkg-photo">
                            
                        </div>
                        <div class="front__face-photo">
                            <img src="{{serviceUrl}}CommiteeImages/{{team.IMG_URL}}" alt="team">
                        </div>
                        <div class="front__text">
                          <h3 class="front__text-header">{{team.NAME}}</h3>
                          <p class="front__text-para"> <i class='bx bxs-phone'></i>{{team.MOBILE_NO}}</p>
                          <p class="front__text-para"><i class='bx bxs-envelope'></i>{{team.EMAIL_ID}}</p>
                        
                        </div>
                      </div>
                      <div class="back">
                        <div class="social-media-wrapper">
                        <a style="margin-top: 20px;" routerLink="/" class="bannerBtn mr-3 mt-3">Contact Us<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10 10h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4z"/></svg></a>

                          
                        </div>
                      </div>
                  
                    </div>
                  </div>
            </div>
            </div>

        </div>
        </div> -->

