import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/Services/api.service';
import { appkeys } from 'src/app/app.constant';

@Component({
  selector: 'app-demoprofilelist',
  templateUrl: './demoprofilelist.component.html',
  styleUrls: ['./demoprofilelist.component.scss']
})
export class DemoprofilelistComponent implements OnInit {
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;
 serviceUrl = appkeys.retriveimgUrl

 BlogData: any = [];
 blogUrl = appkeys.retriveimgUrl
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }
 constructor(public apiService: ApiService ) {
  this.getScreenSize(event);
  if (this.scrWidth <= 500) 
  { 
   this.show50 = true;
 }else{
   this.show50 = false;
 }
 }

ngOnInit(): void {
  this.getBlogData();

}
TeamsData1: any = [];

getTeamsData() {
  this.apiService.getCommeti(0, 0, '', 'desc', ' AND STATUS=1')
    .subscribe(data => {
      this.TeamsData1 = data['data'];
    })
}
BlogOption: OwlOptions = {

  loop: false,
  margin: 30,
  dots: false,
  nav: true,
  autoplay: true,
  autoplayHoverPause: true,
  navText: [
    "<i class='bx bx-chevron-left'></i>",
    "<i class='bx bx-chevron-right'></i>"
  ],
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  navSpeed: 400,
  autoWidth: true
}
Is_blogShow: boolean;
Is_blogShow1: boolean;
getIdOfBlog(event) {
  localStorage.setItem("BlogssId", event);
}
getBlogData() {
  this.apiService.getFacilities(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
    .subscribe(data => {
      this.BlogData = data['data'];
      if(this.BlogData.length < 3){
        
        this.Is_blogShow = true;
        this.Is_blogShow1 = false;

      }
      else{

        this.Is_blogShow1 = true;
        this.Is_blogShow = false;
      }
      setTimeout(() => {

        if(data['count']>=2){
          this.BlogOption = {
     
            loop:(data['data'].length>3) ? true: false,
            margin: 30,
            dots: false,
            nav: true,
            autoplay: false,
            autoplayHoverPause: true,
            navText: [
              "<i class='bx bx-chevron-left'></i>",
              "<i class='bx bx-chevron-right'></i>"
            ],
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            navSpeed: 400,
            autoWidth: true,
            responsive: {
              0: {
                items: 1
              },
              576: {
                items: 1
              },
              768: {
                items: 3
              },
              1200: {
                items: 3
              }
            }
          }
        }else{
          this.BlogOption = {
     
            loop:(data['data'].length>3) ? true: false,
            margin: 30,
            dots: false,
            nav: true,
            autoplay: true,
            autoplayHoverPause: true,
            navText: [
              "<i class='bx bx-chevron-left'></i>",
              "<i class='bx bx-chevron-right'></i>"
            ],
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            navSpeed: 400,
            autoWidth: true,
          }
        }
      }, 500);
    })
}
}
