import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
 }
  constructor(private api:ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }
  name:any;
  titles:any;
  desc:any;
test:any=[];

TestOption: OwlOptions = {
  items:1,
		loop: true,
		margin: 30,
		dots: false,
		nav: true,
		autoplay: true,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
}


  ngOnInit(): void {
    this.api.getTestmonials(0,0,'SEQUENCE_NO','asc',' AND STATUS=1')
    .subscribe((data:any) => {
      if (data['code'] == 200)
         this.test = data['data'];

         
        setTimeout(() => {

          this.TestOption = {

            items:1,
            loop: true,
            margin: 30,
            dots: true,
            nav: false,
            autoplay: true,
            autoplayHoverPause: true,
            navText: [
              "<i class='bx bx-chevron-left'></i>",
              "<i class='bx bx-chevron-right'></i>"
            ]
          }
        }, 500);
    })

  }

}