import { Component, OnInit, HostListener } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';
@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {
  TeamsData: any = []
  serviceUrl = appkeys.retriveimgUrl

  scrHeight: any;
  scrWidth: any;
  show50: boolean = false;


  @HostListener('window:resize', ['$event']) getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(public apiService: ApiService) {


    this.apiService.getCommeti(0, 0, '', 'desc', ' AND ID=' + localStorage.getItem("TeamsId"))
      .subscribe(data => {
        this.TeamsData = data['data'][0];
      })

    this.getScreenSize(event);
    if (this.scrWidth <= 500) {
      this.show50 = true;
    } else {
      this.show50 = false;
    }
  }

  //  teamsId:any;
  teamsId = localStorage.getItem("TeamsId");
  ngOnInit(): void {

    this.getTeamsData()
  }
  TeamsData1: any = [];

  getTeamsData() {
    this.apiService.getCommeti(0, 0, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        this.TeamsData1 = data['data'];
      })
  }

  // teamsId:any;
  getI(event: any) {
    this.teamsId = event;

  }
  getIdOfTeams(event) {
    localStorage.setItem("TeamsId", event);
    this.apiService.getCommeti(0, 0, '', 'desc', ' AND ID=' + localStorage.getItem("TeamsId"))
      .subscribe(data => {
        this.TeamsData = data['data'][0];
      })
  }
}
