<!-- Footer Area-->
<footer class="footer-area" style="padding-top: 50px;
padding-bottom: 20px;box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo" style="font-size: 25px;font-weight: 700;color: #f38737;">
                        <!-- <img src="assets/img/GhewareLogo.png" width="145" height="41" alt="logo"> -->
                        Shree Hospital
                    </div>
                    <p style="text-align: justify;" *ngIf="AboutData!=undefined" [innerHTML]="AboutData.TEXT">
                    </p>
                    <ul class="footer-socials">
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.FB_LINK}}" target="_blank"><i
                                    class='bx bxl-facebook facebookIcon'
                                    style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.TW_LINK}}" target="_blank"><i
                                    class='bx bxl-twitter twitterIcon'
                                    style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.SOCIAL_LINK}}" target="_blank"><i
                                    class='bx bxl-linkedin linkedinIcon'
                                    style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.INSTA_LINK}}" target="_blank"><i
                                    class='bx bxl-instagram instagramIcon'
                                    style="background:#0672e1; color: white;"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="footer-widget">
                    <h3>Support</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/services-facilities">Services</a></li>
                        <li><a routerLink="/services_facilities">Facilities</a></li>
                        <li><a routerLink="/team">Committee</a></li>
                        <!-- <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <iframe title="map" *ngIf="url != ''" [src]="url" style="border:0;" width="100%" height="340"
                        allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info.</h3>

                    <ul class="info-list">
                        <li *ngIf="ContactData!=undefined" style="text-align: justify;"><i
                                class='bx bxs-location-plus'></i>
                            {{ContactData.ADDRESS_DETAILS}}</li>
                        <li *ngIf="ContactData!=undefined"><i class='bx bxs-envelope'></i> <a
                                href="mailto:shree.hospital@rediffmail.com">{{ContactData.EMAIL_ID}}</a></li>
                        <li *ngIf="ContactData!=undefined && ContactData.EMAIL_ID2!=''"><i class='bx bxs-envelope'></i> <a
                                href="mailto:shreehospital747@gmail.com">{{ContactData.EMAIL_ID2}}</a></li>


                        <div style="display: flex;">
                            <li *ngIf="ContactData!=undefined && ContactData.CONTACT_NO!=undefined"><i class='bx bxs-phone'></i> <a
                                    href="tel:9860798919">{{ContactData.CONTACT_NO}}</a></li>
                            &nbsp;&nbsp;
                            <li *ngIf="ContactData!=undefined && ContactData.WHATSAPP_NO!=''"><i class='bx bxs-phone'></i> <a
                                    href="tel:9860798919">{{ContactData.WHATSAPP_NO}}</a></li>
                        </div>

                        <li *ngIf="ContactData!=undefined && ContactData.MOBILE_NO2!=''"><i class='bx bxs-phone'></i> <a
                                href="tel:9860798919">{{ContactData.MOBILE_NO2}}</a></li>
                        <div style="display: flex;">
                            <li *ngIf="ContactData!=undefined && ContactData.LAND_LINE1!=undefined"><i class='bx bxs-phone-call'></i><a
                                    href="tel:02164-221082/226082">{{ContactData.LAND_LINE1}}</a></li>&nbsp;&nbsp;
                            <li *ngIf="ContactData!=undefined && ContactData.LAND_LINE2!=''"><i class='bx bxs-phone-call'></i><a
                                    href="tel:02164-221082/226082">{{ContactData.LAND_LINE2}}</a></li>
                        </div>
                        <!-- <li><i class='bx bxs-envelope'></i> <a href="mailto:contact@floza.com">contact@floza.com</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p style="padding-bottom: 10px;">Copyright © {{year}} <a style="color:orange; font-weight: 500;">Shree Hospital,
                Karad.</a>
        <p style="padding-top: 0px;">Designed & Developed By <a style="color:orange; font-weight: 500;"
                href="https://www.uvtechsoft.com/" target="_blank"> UjjVilas Technologies & Software Pvt. Ltd.</a>
    </div>
</div>

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->