import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { appkeys } from 'src/app/app.constant';

@Component({
  selector: 'app-comity-members',
  templateUrl: './comity-members.component.html',
  styleUrls: ['./comity-members.component.scss']
})
export class ComityMembersComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  show50: boolean = false;
  teamid: any;
  TeamsData1: any = [];
  TeamsData2: any = [];
  TeamsData3: any = [];
  TeamsData4: any = [];
  serviceUrl = appkeys.retriveimgUrl

  @HostListener('window:resize', ['$event']) getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(public apiservice: ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) {
      this.show50 = true;
    } else {
      this.show50 = false;
    }
  }
  ngOnInit(): void {
    this.teamid = localStorage.getItem("TeamsId");
    this.getTeamsData();
  }
  getTeamsData() {
    this.apiservice.getCommeti(0, 0, '', 'desc', ' AND STATUS=1 AND ID=' + this.teamid)
      .subscribe(data => {
        this.TeamsData1 = data['data'][0];
        this.TeamsData2 = data['data'][0]['TEACHING_EXPERIENCE'].split(",");
        this.TeamsData3 = data['data'][0]['BASIC_DETAILS'].split(",");
        this.TeamsData4 = data['data'][0]['SOCIAL_COMMITMENTS'].split(",");

      })
  }
  showClick1: boolean = true;
  showClick2: boolean = false;
  showClick3: boolean = false;

  click1() {
    this.showClick1 = true;
    this.showClick2 = false;
    this.showClick3 = false;
  }

  click2() {
    this.showClick1 = false;
    this.showClick2 = true;
    this.showClick3 = false;
  }

  click3() {
    this.showClick1 = false;
    this.showClick2 = false;
    this.showClick3 = true;
  }
}