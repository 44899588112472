<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/servf.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 22px;">
                    <h2>Our Services & Facilities</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services & Facilities</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/servf.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                    padding: 20px 20px;">
                    <h2>Our Services & Facilities</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services & Facilities</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="our-services-area" style="padding-top: 30px;
padding-bottom: 5px;">
    <div class="container">
        <div class="col-md-12" class="gallery">
            <ul class="gallery-filter list-unstyled list-inline text-center" style="margin-bottom: 0px;">
                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;  background-color: #f7941d;" (click)="getall()">
                    <span>Services</span>
                </li>
                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                 cursor: pointer; background-color: #8732de;" routerLink="/services_facilities">
                    <span>Facilities</span>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Our Services Area -->
<div class="our-services-area" style="padding-top: 35px;
padding-bottom: 20px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 col-sm-6 col-xl-4" *ngFor="let data of ServicesData">
                <div class="service-card-two">
                    <img *ngIf="ServicesData!=undefined" src="{{serviceUrl}}serviceImages/{{data.IMAGE_URL}}"
                        height="215" width="100%" alt="image" style="
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                    ">
                    <div class="service-caption" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                    height: 200px;padding: 20px;text-align: left;">
                        <h3 *ngIf="ServicesData!=undefined" class="limit"
                            style="min-height: 47px;font-size: 19px;margin-bottom: 5px;font-weight: 500;">{{data.TITLE}}
                        </h3>
                        <p *ngIf="ServicesData!=undefined" style="cursor: pointer;text-align: justify;margin-bottom: 13px;
                        margin-top: 7px;" class="limit1"
                            (click)="getIdOfService(data.ID)" data-bs-toggle="modal" data-bs-target="#descriptionModal">
                            {{data.DESCRIPTION}}</p>
                            <a  (click)="getIdOfService(data.ID)" data-bs-toggle="modal" data-bs-target="#descriptionModal" style="color: #f38737;font-weight: 500;cursor: pointer;"> View More &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f38737" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                              </svg>
                              </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="totalcount>9">
                <ul class="pagination">
                    <li class="page-item " style="cursor: pointer;">
                        <a class="page-link" style="font-size: 11px;" (click)="previousPage()">
                            << </a>
                    </li>
                    <li class="page-item active" *ngFor="let p of pages" [ngClass]="{'active': p === currentPage}"
                        style="cursor: pointer;">
                        <a class="page-link" (click)="pagination(p)">{{p}}</a>
                    </li>
                    <li class="page-item" style="cursor: pointer;">
                        <a class="page-link" style="font-size: 11px;" (click)="nextPage()">
                            >>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="descriptionModal" tabindex="-1" aria-labelledby="descriptionModalTitle" aria-hidden="true">
    <div class="modal-dialog"  style="max-width: 600px;">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="descriptionModalTitle" *ngIf="ServicesData1!=undefined"  style="color: #f38737;">Services</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" *ngIf="ServicesData1!=undefined" style="padding: 25px; text-align: justify;">
                <h6 *ngIf="ServicesData1!=undefined">{{ServicesData1.TITLE}}</h6>
                <p *ngIf="ServicesData1!=undefined"> {{ServicesData1.DESCRIPTION}}</p>
                <div *ngIf="services.length>0 && services!=''">
                    <ul>
                        <li *ngFor="let data of services">{{data}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>