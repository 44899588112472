import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
   }
  constructor() { 
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
  }

  ngOnInit(): void {
  }

}
