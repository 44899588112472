import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent implements OnInit {
  ServicesData: any = []
  serviceUrl = appkeys.retriveimgUrl
  totalcount: any = 0
  pageSize: any = 9
  pages: any = [];
  ServicesData1: any = [];
  FacilityData: any = [];
  FacilityDataCount: any;
  pages1: any = [];
  scrHeight: any;
  scrWidth: any;
  show50: boolean = false;
  showcolor1 = 1
  showcolor = 0
  @HostListener('window:resize', ['$event']) getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(public apiService: ApiService) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) {
      this.show50 = true;
    } else {
      this.show50 = false;
    }
  }

  ngOnInit(): void {
    this.getall1();
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: { 0: { items: 1 } },
    nav: false
  }
  truedata: boolean = true;
  getall1() {
    this.apiService.getFacilities(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        if (data['code'] == 200) {
          this.truedata = false;
          this.FacilityData = data['data'];
          this.FacilityDataCount = data['count']
          this.showcolor = 1
          this.showcolor1 = 0
          this.totalPages1 = Math.ceil(this.FacilityDataCount / this.pageSize);
          for (var i = 0; i <= (this.FacilityDataCount / this.pageSize); i++) {
            this.pages1.push(i + 1);
          }
        }
      })
  }
  getFacilities() {
    this.apiService.getFacilities(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        if (data['code'] == 200) {
          this.truedata = false;
          this.FacilityData = data['data'];
          this.FacilityDataCount = data['count']
          this.totalPages1 = Math.ceil(this.FacilityDataCount / this.pageSize);
          for (var i = 0; i <= (this.FacilityDataCount / this.pageSize); i++) {
            this.pages1.push(i + 1);
          }
        }
      })
  }

  currentPage1 = 1;
  totalPages1 = 1;
  pagination1(p: any) {
    this.apiService.getFacilities(p, this.pageSize, '', 'desc', 'AND IS_ACTIVE=1').subscribe(data => {
      if (data.code == "200") {
        this.truedata = false;
        this.FacilityDataCount = data['count']
        this.FacilityData = [...[], ...data['data']];
        this.currentPage1 = p;;
      }
      else {
        this.FacilityData = []
        this.totalPages1 = 1;
      }
    })
  }

  previousPage1() {
    if (this.currentPage1 > 1) {
      this.pagination1(this.currentPage1 - 1);
    }
  }

  nextPage1() {
    if (this.currentPage1 < this.totalPages1) {
      this.pagination1(this.currentPage1 + 1);
    }
  }
  facilities: any = [];
  FacilityData1: any = [];
  getIdOfFacility(event) {
    localStorage.setItem("FacilityId", event);
    this.apiService.getFacilities(0, 0, '', 'desc', ' AND ID=' + localStorage.getItem("FacilityId"))
      .subscribe(data => {
        this.FacilityData1 = data['data'][0];
        this.facilities = data['data'][0]['SUB_TITLE'].split(",");
      })
  }
}
