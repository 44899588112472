<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Video Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Video Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Video Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Video Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- End Page banner Area -->

<!--Album area-->


<!-- Gallery Area -->
<div class="gallery-area" style="padding-top: 40px;
padding-bottom: 30px;">
    <div class="container">
        <div class="gallery-title" style="margin-bottom: 15px;">
            <h3>Our Video Gallery</h3>
        </div>

        <div class="col-md-12" class="gallery">
            <ul class="gallery-filter list-unstyled list-inline text-center" style="margin-bottom: 40px;">
            
                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor1 == 1 ? {'background':'#f7941d'} : {'background':'#8732de'}"
                (click)="getall()">
                 <span>All</span></li>


                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor == j ? {'background':'#f7941d'} : {'background':'#8732de'}"
                 *ngFor="let x of albums;let i=index; let j=index;" (click)="get(x.ID)" (click)=" getI(i)">
                 <span *ngIf="albums!=undefined">
                    {{x.TITLE}}
                 </span></li>
                
             
            </ul>
        </div> 

        <div class="row" >
            <div *ngIf="Videocount==0" style="margin-top: 55px;">
                <h3 class="text-center" style="font-size: larger;color: red;">Data Not Found..!</h3>
          </div>
            <div class="col-lg-4 col-md-6" *ngFor="let x of videos;let i=index; let j=index">


                    <div class="floza-gallery" >
                        <div>
                        
                         <!-- <img src="{{serviceUrl}}video/{{x.VIDEO_URL}}" alt="image" 
                         height="191" width="366">
                        </div> -->

                        <video  width="366" (playing)="onPlayingVideo($event)"
                        height="191" controls   >
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/mp4">
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/webm">
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/ogg">
                    </video>
                </div>
            </div>
            <h5 style="text-align: center;margin-bottom: 20px;color:#8732DE;">{{x.VIDEO_NAME}}</h5>
 </div>
    </div>
</div>
