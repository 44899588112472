<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 22px;">
                    <h2>Page Not Found</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a>Page Not Found</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Team.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                    padding: 20px 20px;">
                    <h2>Page Not Found</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a>Page Not Found</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End Page banner Area -->

<!-- 404 Error Area -->
<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error">
                    <img class="error-image" src="assets/img/error.png" alt="image">
                    <h2>Page Not Found</h2>
                    <div class="error-btn">
                        <a routerLink="/">Back To Home</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>